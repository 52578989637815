<template>
    <div>
      <v-card class="pa-5">
        <div class="filters">
          <v-form
            ref="form"
            v-model="valid"
          >
            <div class="w-100 d-flex justify-lg-space-between my-4">
        <div>
          <SearchInput v-model="searchInputValue" />
        </div>
              <div class="actions d-flex">
                <v-btn
                  color="primary"
                  class="mt-4"
                  @click="openCreateTokenDialog"
                >
                  {{ $t('common.add') }}
                </v-btn>
              </div>
            </div>
          </v-form>
        </div>
        <div>
          <data-table
            ref="dataTable"
            :headers="headers"
            item-key="name"
            endpoint="/appToken/getTokens"
            :search="searchInputValue"
            serverPagination
            :additionalParams="params"
          >
          <template v-slot:item.expiration="{item}">
            {{ item.expiration | dateFormat('DD.MM.YYYY HH:mm') }}
          </template>
          <template v-slot:item.isActive="{item}">
            {{ item.isActive ? $t("appTokens.active"):$t("appTokens.inActive")}}
          </template>
          <template v-slot:item.menu="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                link
                @close="closeAppTokenDialog"
                @click="openAppTokenDialog(item)"
              >
                <v-list-item-title>
                  {{ $t("common.details") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                @click="openCreateTokenDialog(item)"
              >
                <v-list-item-title>
                  {{ $t("common.edit") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                @click="openDeleteTokenDialog(item)"
              >
                <v-list-item-title>
                  {{ $t("common.remove") }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
          </data-table>
        </div>
      </v-card>
    <AplicationTokenDialog
      :token="selectedToken"
      :tokenId="selectedTokenId"
      :isActive="selectedTokenActive"
      :isOpen="isAppTokenDialogOpen"
      @close="closeAppTokenDialog"
    />
    <v-dialog v-model="addTokenDialog" persistent max-width="500px">
      <v-card class="py-4">
        <v-card-title>{{ $t('users.dialogs.setRole.title')}}</v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="addTokenForm" :disabled="loadingDialog">
              <v-row>
                <v-col cols="12">
                <label for="name">{{ $t('appTokens.name') }}</label>
                <v-text-field
                  v-model="newToken.name"
                  id="name"
                  outlined
                  dense
                ></v-text-field>
                <label for="name">{{ $t('appTokens.validInDays') }}</label>
                <v-text-field
                  v-model="newToken.validInDays"
                  id="validInDays"
                  outlined
                  dense
                  type="number"
                ></v-text-field>
                <label for="name">{{ $t('appTokens.requestLimit') }}</label>
                <v-text-field
                  v-model="newToken.requestLimit"
                  id="requestLimit"
                  outlined
                  dense
                  type="number"
                ></v-text-field>
              </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="px-8">
          <v-spacer></v-spacer>
          <v-btn
            color="secodary"
            outlined
            depressed
            class="mr-2"
            :disabled="loadingDialog"
            @click="closeCreateTokenDialog"
          >
            {{ $t('common.cancel')}}
          </v-btn>
          <v-btn
            color="primary"
            depressed
            :loading="loadingDialog"
            @click="addNewDialogSubmit"
          >
            {{ $t('common.save')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" persistent max-width="500px">
        <v-card class="py-4">
          <v-card-title>
          <h3 class="primary--text mb-2">
                    {{ $t("appTokens.removeToken") }}
                  </h3></v-card-title>
          <v-card-actions class="px-8">
            <v-spacer></v-spacer>
            <v-btn
              color="secodary"
              outlined
              depressed
              class="mr-2"
              :disabled="loading"
              @click="closeDeleteDialog"
            >
              {{ $t('common.cancel')}}
            </v-btn>
            <v-btn
              color="primary"
              depressed
              :loading="loading"
              @click="deleteToken"
            >
              {{ $t('common.remove')}}
            </v-btn>
          </v-card-actions>
        </v-card>
       </v-dialog>
    </div>
  </template>

<script>
import services from '@/services'
import moment from 'moment'
import SearchInput from '@/components/search/SearchInput.vue'
import AplicationTokenDialog from '@/views/auth/dialogs/AplicationTokenDialog.vue'

export default {
  name: 'Logs',
  components: {
    SearchInput,
    AplicationTokenDialog
  },
  data () {
    return {
      newToken: {},
      isAppTokenDialogOpen: false,
      selectedToken: null,
      selectedTokenId: null,
      selectedTokenActive: false,
      addTokenDialog: false,
      searchInputValue: '',
      updateTokenId: null,
      params: {
        localTo: null,
        localFrom: null,
        user: null
      },
      filters: {
        localTo: null,
        localFrom: null,
        user: null
      },
      valid: true,
      logs: [],
      dataTableOptions: {
        page: 1,
        pageSize: 100
      },
      totalLogsCount: 0,
      headers: [
        {
          text: this.$t('appTokens.name'),
          value: 'name'
        },
        {
          text: this.$t('appTokens.isActive'),
          value: 'isActive'
        },
        {
          text: this.$t('appTokens.expirationDate'),
          value: 'expiration'
        },
        {
          text: this.$t('appTokens.validInDays'),
          value: 'validInDays'
        },
        {
          text: this.$t('appTokens.requestLimit'),
          value: 'requestLimit'
        },
        {
          text: '',
          value: 'actions',
          align: 'end'
        },
        {
          text: '',
          align: 'end',
          width: '75',
          value: 'menu',
          sortable: false
        }
      ],
      loading: false,
      loadingDialog: false,
      deleteDialog: false
    }
  },
  computed: {
  },
  created () {
  },
  methods: {
    async addNewDialogSubmit () {
      this.loadingDialog = true
      try {
        if (this.updateTokenId) {
          await services.put('AppToken/UpdateToken', this.newToken)
          this.updateTokenId = null
        } else {
          await services.post('AppToken/AddToken', this.newToken)
        }

        await this.getTokens()
        await this.$store.dispatch(
          'setSuccessNotification',
          this.$t('common.confirmOperation')
        )
        this.addTokenDialog = false
        this.newToken = {}
      } catch (e) {}
      this.loadingDialog = false
    },
    async getTokens () {
      await this.$refs.dataTable.getData()
    },
    closeAppTokenDialog () {
      this.isAppTokenDialogOpen = false
      this.getTokens()
    },
    closeDeleteDialog () {
      this.deleteDialog = false
      this.selectedToken = null
      this.selectedTokenId = null
    },
    openDeleteTokenDialog (item) {
      this.selectedToken = item.token
      this.selectedTokenId = item.id
      this.deleteDialog = true
    },
    openAppTokenDialog (item) {
      this.selectedToken = item.token
      this.selectedTokenId = item.id
      this.selectedTokenActive = item.isActive
      this.isAppTokenDialogOpen = true
    },
    async deleteToken () {
      this.loading = true
      try {
        await services.delete('/AppToken/DeleteToken', {
          tokenId: this.selectedTokenId
        })
      } catch (e) {
      } finally {
        this.loading = false
        this.closeDeleteDialog()
      }
      await this.getTokens()
    },
    async openCreateTokenDialog (token) {
      if (token) {
        this.updateTokenId = token.id
        this.newToken = {
          tokenId: token.id,
          name: token.name,
          validInDays: token.validInDays,
          requestLimit: token.requestLimit
        }
      }
      this.addTokenDialog = true
    },
    async closeCreateTokenDialog () {
      this.updateTokenId = null
      this.addTokenDialog = false
      this.loadingDialog = false
      this.newToken = {}
    },
    async createTokenXX () {
      await services.post('Logs/ExportToCSV', {
        userId: this.filters.user,
        from: moment(this.filters.localFrom).startOf('day'),
        to: moment(this.filters.localTo).endOf('day')
      })
    }
  }
}
</script>

<template>
    <v-dialog v-model="isOpen" persistent width="450px">
      <v-card outlined>
        <v-card-title>
          <h4 class="primary--text">
            {{ $t('apiToken.dialog.label') }}
          </h4>
        </v-card-title>
        <v-card-text>
            <span class="mt-5">  {{ $t('apiToken.dialog.state') }}
            <b>{{ isActive
                ?`${$t('apiToken.dialog.active')}`
                : $t('apiToken.dialog.inactive')}}
            </b>
            </span>
            <v-spacer></v-spacer>
            <v-btn v-if="token && isActive" :color="!isActive ? 'green':'#ff7a70'" @click="blockToken()" outlined   >
                {{ $t('apiToken.dialog.disableBtn') }}
            </v-btn>
            <v-btn v-else :color="!isActive ? 'green':'#ff7a70'" @click="unBlockToken()" outlined   >
                {{ $t('apiToken.dialog.enableBtn') }}
            </v-btn>
            <br>
            Token:
            <v-textarea outlined :loading="isLoading" readonly v-model="bearerToken" height="200"></v-textarea>
          <v-card-actions>
            <v-btn color="primary" @click="addOrUpdateToken()" depressed>
                {{ $t('apiToken.dialog.generateNewBtn') }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn  @click="close" outlined>
              {{ $t('common.close') }}
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </template>

<script>
import Service from '@/services'
export default {
  props: {
    isOpen: {
      type: Boolean
    },
    token: {
      type: String
    },
    tokenId: {
      type: String
    },
    isActive: {
      type: Boolean
    }
  },
  data () {
    return {
      isLoading: false,
      tokenProp: ''
    }
  },
  computed: {
    bearerToken () {
      return this.tokenProp ? 'Bearer ' + this.tokenProp : ''
    }
  },
  methods: {
    async blockToken () {
      try {
        this.isLoading = true
        await Service.post('/AppToken/BlockToken', { tokenId: this.tokenId })

        await this.$store.dispatch(this.$t('apiToken.dialog.tokenDisabledNotification')
        )
        this.isActive = false
        this.isLoading = false
      } catch (e) {
        this.isLoading = false
      }
    },
    async unBlockToken () {
      try {
        this.isLoading = true
        await Service.post('/AppToken/UnBlockToken', { tokenId: this.tokenId })

        await this.$store.dispatch(this.$t('apiToken.dialog.tokenEnabledNotification')
        )
        this.isActive = true
        this.isLoading = false
      } catch (e) {
        this.isLoading = false
      }
    },
    async addOrUpdateToken () {
      const oldToken = this.token
      try {
        this.isLoading = true
        this.tokenProp = 'loading'
        const { data } = await Service.post('/AppToken/RefreshToken', { tokenId: this.tokenId })

        await this.$store.dispatch(
          'setSuccessNotification', this.$t('apiToken.dialog.tokenEnabledNotification')
        )

        this.tokenProp = data
        this.isActive = true
        this.isLoading = false
      } catch (e) {
        this.isLoading = false
        this.tokenProp = oldToken
      }
    },
    close () {
      this.$emit('close')
    }
  },
  watch: {
    isOpen: function (val) {
      if (val) {
        this.tokenProp = this.token
      }
    }
  }
}
</script>
